<template>
  <v-row class="mt--30">
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(item, i) in portfolio"
      :key="i"
    >
      <div class="im_portfolio">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <a href="portfolio-details.html">
              <img class="w-100" :src="item.src" alt="Portfolio Images" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <div class="category_list">{{ comingSoonDate(item.date) }}</div>
              <!-- <h4 class="heading-title">{{ item.title }}</h4> -->
            </div>
            <div class="portfolio_hover">
              <!-- <p>{{ item.description }}</p> -->
              <div>
                <a
                  v-if="item.playstore"
                  style="margin-right: 10px"
                  :href="
                    item.playstore +
                    '&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  "
                >
                  <img
                    width="120"
                    alt="Get it on Google Play"
                    src="../../assets/images/badges/play_store_ptbr.png"
                  />
                </a>
                <a v-if="item.appstore" :href="item.appstore">
                  <img
                    width="107"
                    alt="Get it on App Store"
                    src="../../assets/images/badges/app_store_ptbr.svg"
                  />
                </a>
                <a v-if="!item.appstore && !item.playstore && item.date != '-'" href="#">
                  <div class="app-out">
                    <span>{{ $t('currentlyDown') }}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      portfolioContent: [
	      {
          src: require("../../assets/images/portfolio/apps/Nexwin.png"),
          category: "Jogos",
          title: "Nexwin",
          description: "A plataforma desenvolvida para conectar campeões. Ganhe dinheiro enquanto se diverte jogando!",
          date: "-",
        },
        {
          src: require("../../assets/images/portfolio/apps/PreOpp.png"),
          category: "Saúde",
          title: "PreOpp",
          description: "Acompanhe seu pré-operatório: Agenda de Avaliações, Exames e muito mais para seu pré operatório.",
          date: "14-03-2024",
          playstore: "https://play.google.com/store/apps/details?id=br.com.preopp",
        },
	      {
          src: require("../../assets/images/portfolio/apps/Borg Log.png"),
          category: "Logística",
          title: "Borg Log",
          description: "Para Motoristas da Borg Express e Borghetti",
          date: "21-12-2023",
          playstore: "https://play.google.com/store/apps/details?id=br.com.borghettibr.app&pli=1",
        },
        {
          src: require("../../assets/images/portfolio/apps/SecretSplit.png"),
          category: "Saúde",
          title: "SecretSplit",
          description: "Ideal para a proteção de Crypto-Wallets!",
          date: "20-12-2021",
          playstore: "https://play.google.com/store/apps/details?id=com.secretsplit.simonini",
          appstore: "https://apps.apple.com/us/app/secretsplit/id1600970205",
        },
        {
          src: require("../../assets/images/portfolio/apps/We are Good.png"),
          category: "Saúde",
          title: "We are Good",
          description: "Rede Social do bem. Viabilize boas ações.",
          date: "01-11-2021",
          playstore:
            "https://play.google.com/store/apps/details?id=com.guaranadigital.wag",
          appstore: "https://apps.apple.com/app/id1516113992",
        },
        {
          src: require("../../assets/images/portfolio/apps/Lucateo.png"),
          category: "Saúde",
          title: "Lucateo",
          description: "Jogo viciante de jogadas combinadas.",
          date: "14-09-2021",
          playstore: "https://play.google.com/store/apps/details?id=com.simonini.mateoluca",
        },
        {
          src: require("../../assets/images/portfolio/apps/TV Rede.png"),
          category: "Saúde",
          title: "TV Rede",
          date: "24-04-2021",
          description: "App de mídia digital para Smart TVs.",
        },
        {
          src: require("../../assets/images/portfolio/apps/Hyupp.png"),
          category: "Saúde",
          title: "Hyupp",
          description: "Carregue seu celular com Hyupp!",
          date: "05-12-2020",
          playstore: "https://play.google.com/store/apps/details?id=com.juhui.hyupp201123",
          appstore: "https://apps.apple.com/br/app/hyupp/id1540200984?l=en",
        },
        {
          src: require("../../assets/images/portfolio/apps/OLEM.png"),
          category: "Saúde",
          title: "OLEM",
          description: "App de RA do álbum do rapper Bk' OLEM.",
          date: "07-09-2020",
        },
        {
          src: require("../../assets/images/portfolio/apps/Plataforma em Rede.png"),
          category: "Serviços",
          title: "Plataforma em Rede",
          description: "Aplicativo de Vídeos e Ensino a Distância.",
          date: "27-08-2020",
        },
        {
          src: require("../../assets/images/portfolio/apps/Fotovio.png"),
          category: "ONG",
          title: "Fotovio",
          description: "Envie mensagens de vídeo com o Fotovio.",
          date: "30-07-2020",
          playstore:
            "https://play.google.com/store/apps/details?id=com.simonini.fotovioapp",
          appstore: "https://apps.apple.com/br/app/fotovio/id1522595568",
        },
        {
          src: require("../../assets/images/portfolio/apps/deBle.png"),
          category: "ONG",
          title: "deBlé",
          description: "Pesquisar preços e comprar com fornecedor.",
          date: "07-07-2020",
        },
        {
          src: require("../../assets/images/portfolio/apps/Barilife.png"),
          category: "Saúde",
          title: "Barilife",
          description: "A Sociedade Brasileira de Cirurgia Bariatrica e Metabolica (SBCBM) desenvolveu o aplicativo pensando em você, paciente bariatrico.",
          date: "13-11-2019",
          playstore:
            "https://play.google.com/store/apps/details?id=br.simonini.geopost&hl=pt_BR",
        },
        {
          src: require("../../assets/images/portfolio/apps/SBGF.png"),
          category: "ONG",
          title: "SBGf",
          description: "Canal de Produção dos Eventos da SBGf",
          date: "15-07-2019",
          playstore:
            "https://play.google.com/store/apps/details?id=br.simonini.geopost&hl=pt_BR",
        },
        {
          src: require("../../assets/images/portfolio/apps/Skate Runner.png"),
          category: "ONG",
          title: "SkateRunner",
          description: "Jogo de Skate no estilo 'Corrida sem fim'",
          date: "17-08-2018",
        },
        {
          src: require("../../assets/images/portfolio/apps/QS Videos.png"),
          category: "ONG",
          title: "QS Vídeos",
          description: "Aplicativo de Vídeos e Ensino a Distância",
          date: "27-05-2018",
        },
        {
          src: require("../../assets/images/portfolio/apps/BlockBlock Antivirus.png"),
          category: "ONG",
          title: "BlockBlock Antivírus",
          description: "Aplicação de Segurança para Android",
          date: "10-03-2018",
        },
        {
          src: require("../../assets/images/portfolio/apps/Prisma Mobile.png"),
          category: "ONG",
          title: "Prisma Mobile",
          description: "Gestão de Ativos e Ordens de Serviço",
          date: "10-12-2017",
          playstore: 
            "https://play.google.com/store/apps/details?id=br.com.sisteplant.vivoflm&hl=pt_BR&gl=US",
        },
        {
          src: require("../../assets/images/portfolio/apps/Bravecto Game.png"),
          category: "Game",
          title: "Bravecto Game",
          description: "Você terá que combater pulgas e carrapatos de uma forma divertida, do mesmo jeito que o Bravecto faz.",
          date: "28-07-2017",
          playstore: 
            "https://www.9apps.com/android-apps/msd-bravogame/",
        },
        {
          src: require("../../assets/images/portfolio/apps/TV QS.png"),
          category: "ONG",
          title: "TV QS",
          description: "App de mídia digital para Smart TVs",
          date: "18-02-2017",
        },
        {
          src: require("../../assets/images/portfolio/apps/CRMOBI.png"),
          category: "ONG",
          title: "CRMOBI",
          description: "Aplicativo de checklist automotivo",
          date: "03-10-2016",
          playstore: "https://play.google.com/store/apps/details?id=br.com.checkmobi&hl=pt_BR",
        },
        {
          src: require("../../assets/images/portfolio/apps/Qualidade em Saude.png"),
          category: "ONG",
          title: "Qualidade em Saúde",
          description: "Localize consultórios Membros QS",
          date: "25-12-2015",
        },
        {
          src: require("../../assets/images/portfolio/apps/FLGRNT.png"),
          category: "ONG",
          title: "FLGRNT",
          description: "Disco de música em forma de App",
          date: "07-12-2015",
        },
        {
          src: require("../../assets/images/portfolio/apps/OmniCut.png"),
          category: "ONG",
          title: "OmniCut",
          description: "É um jogo único de cérebro e quebra-cabeça",
          date: "30-10-2015",
        },
        {
          src: require("../../assets/images/portfolio/apps/Transito13.png"),
          category: "ONG",
          title: "Trânsito13",
          description: "Atravesse a rua nesse trânsito maluco",
          date: "25-07-2015",
        },
        {
          src: require("../../assets/images/portfolio/apps/DollySheep.png"),
          category: "ONG",
          title: "DollySheep",
          description: "Jogo para Android e iOS",
          date: "25-03-2015",
        },
        {
          src: require("../../assets/images/portfolio/apps/FoodTruckApp.png"),
          category: "ONG",
          title: "FoodTruckApp",
          description: "FoodTrucks em tempo real no mapa",
          date: "21-02-2015",
        },
        {
          src: require("../../assets/images/portfolio/apps/Galo Loco.png"),
          category: "ONG",
          title: "Galo Loco",
          description: "Jogo Nativo para iOS",
          date: "25-09-2014",
        },
        {
          src: require("../../assets/images/portfolio/apps/Diamante de Gould.png"),
          category: "ONG",
          title: "Diamante de Gould",
          description: "Jogo Nativo para Android.",
          date: "01-09-2014",
        },
        {
          src: require("../../assets/images/portfolio/apps/Bebida Liberada.png"),
          category: "ONG",
          title: "Bebida Liberada",
          description: "Aplicativo do Canal do Bebida Liberada",
          date: "02-03-2014",
        },
        {
          src: require("../../assets/images/portfolio/apps/Zone Play.png"),
          category: "ONG",
          title: "Zone Play",
          description: "Aplicativo do Canal do Youtube ZonePlay",
          date: "10-10-2013",
        },
      ],
    };
  },
  computed: {
    portfolio() {
      return this.portfolioContent.map((e) => ({ ...e, date: this.convertDate(e.date) }));
    }
  },
  methods: {
    convertDate(dateString) {
      if (dateString === '-') {
        return '-';
      }
      const [day, month, year] = dateString.split('-');
      const date = new Date(`${year}-${month}-${day}`);
      return this.$t('release') + ": " + moment(date).format(this.$t('dateFormat'));
    },
    comingSoonDate(dateString) {
      if (dateString === '-') {
        return this.$t('comingSoon');
      }
      return dateString;
    }
  }
};
</script>
